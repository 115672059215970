import * as React from "react"

import logo from "../images/logo.jpeg"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  textAlign: 'center',
  lineHeight: 1.5
}



// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Algemene electriciteitswerken – K.P. Electro</title>
      <img  style={{width: "100%", maxWidth: "250px"}} src={logo} alt={"K.P. Electro"} />

      <p>Kevin Pauwels<br />
        GSM: <a href={"tel:+32 484 47 39 67"}>+32 484 47 39 67</a><br />
        Email: <a href={"mailto:info@kp-electro.be"}>info@kp-electro.be</a><br />
        <br />
        Ondernemingsnummer: 0718.601.437
      </p>
    </main>
  )
}

export default IndexPage
